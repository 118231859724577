<template>
    <div class="pc reserva booking-details">

        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>

        <header class="p-3" v-if="formBookingDetails.reservation">
            <h1 v-cloak>
                <routerLink :to="{ name: 'Booking', params: { bookingId: booking.reservation }, query: { id: $route.query.id }}">
                    <i class="fal fa-chevron-left"></i>
                    {{ translator.reserva }}
                    {{ booking.reservation }}
                </routerLink>
            </h1>
        </header>

        <div v-if="formBookingDetails.reservation" class="container">
            <div>
                <label for="reservation" class="pb-1" v-cloak>
                    {{ translator.reserva }}
                </label>
                <input id="reservation"
                       v-model="formBookingDetails.reservation"
                       disabled
                       type="text"
                       class="form-control" />
            </div>

            <label for="name" class="pt-3 pb-1" v-cloak>{{ translator.nom }}</label>
            <input id="name"
                   v-model="formBookingDetails.name"
                   type="text"
                   class="form-control"
                   autocomplete="noComplete"
                   :disabled="formBookingDetails.isDisabled"
                   required />

            <label for="surname" class="pt-3 pb-1" v-cloak>
                {{ translator.cognoms }}
            </label>
            <input id="surname"
                   v-model="formBookingDetails.surname"
                   type="text"
                   class="form-control"
                   autocomplete="noComplete"
                   :disabled="formBookingDetails.isDisabled"
                   required />

            <label for="email" class="pt-3 pb-1" v-cloak>
                {{ translator.email }}
            </label>
            <input id="email"
                   v-model="formBookingDetails.email"
                   type="email"
                   class="form-control"
                   autocomplete="noComplete"
                   :disabled="formBookingDetails.isDisabled"
                   required />

            <label for="phone" class="pt-3 pb-1" v-cloak>
                {{ translator.telefon }}
            </label>
            <input id="phone"
                   v-model="formBookingDetails.phone"
                   type="tel"
                   class="form-control"
                   autocomplete="noComplete"
                   :disabled="formBookingDetails.isDisabled"
                   required />

            <label for="address" class="pt-3 pb-1" v-cloak>
                {{ translator.adreca }}
            </label>
            <input id="address"
                   v-model="formBookingDetails.adress"
                   type="text"
                   class="form-control"
                   autocomplete="noComplete"
                   :disabled="formBookingDetails.isDisabled"
                   required />

            <label for="city" class="pt-3 pb-1" v-cloak>{{ translator.poble }}</label>
            <input id="city"
                   v-model="formBookingDetails.city"
                   type="text"
                   class="form-control"
                   autocomplete="noComplete"
                   :disabled="formBookingDetails.isDisabled"
                   required />

            <label for="country" class="pt-3 pb-1" v-cloak>
                {{ translator.pais }}
            </label>
            <select id="country"
                    v-model="formBookingDetails.country"
                    class="form-control"
                    :disabled="formBookingDetails.isDisabled"
                    v-cloak>
                <option v-for="(country, index) in countries"
                        :value="country.alpha2Code"
                        :key="index"
                        v-cloak>
                    {{ country.name }}
                </option>
            </select>

            <label for="zip" class="pt-3 pb-1" v-cloak>{{ translator.dip }}</label>
            <input id="zip"
                   v-model="formBookingDetails.zip"
                   type="text"
                   class="form-control"
                   autocomplete="noComplete"
                   :disabled="formBookingDetails.isDisabled"
                   required />

            <div class="row align-items-end">
                <div class="col-12 col-md-4">
                    <label for="numDocument" class="pt-3 pb-1" v-cloak>
                        {{ translator.dnipassaport }}
                    </label>
                    <input id="numDocument"
                        v-model="formBookingDetails.numDocument"
                        type="text"
                        class="form-control"
                        autocomplete="noComplete"
                        v-bind:disabled="formBookingDetails.isDisabled" />
                </div>
                <div class="col-12 col-md-8">
                    <label for="countryNacionality" class="pt-3 pb-1" v-cloak>
                        {{ translator.nacionalitat }}
                    </label>
                    <select id="countryNacionality"
                            v-model="formBookingDetails.countryNacionality"
                            class="form-control"
                            v-bind:disabled="formBookingDetails.isDisabled"
                            v-cloak>
                        <option v-for="(country, index) in countries"
                                :value="country.alpha2Code"
                                :key="index"
                                v-cloak>
                            {{ country.name }}
                        </option>
                    </select>
                </div>

            </div>

            <div>
                <label class="pt-3 pb-1" v-cloak>{{ translator.persones }}</label>
                <div class="row">
                    <div class="col-4">
                        <input id="adults"
                               v-model="formBookingDetails.adults"
                               type="text"
                               class="form-control"
                               :disabled="formBookingDetails.isDisabled"
                               autocomplete="noComplete" />
                        <label for="adults" v-cloak>{{ translator.adults }}</label>
                    </div>
                    <div class="col-4">
                        <input id="children"
                               v-model="formBookingDetails.children"
                               type="text"
                               class="form-control"
                               :disabled="formBookingDetails.isDisabled"
                               autocomplete="noComplete" />
                        <label for="children" v-cloak>{{ translator.nens }}</label>
                    </div>
                    <div class="col-4">
                        <input id="babies"
                               v-model="formBookingDetails.babies"
                               class="form-control"
                               :disabled="formBookingDetails.isDisabled"
                               autocomplete="noComplete" />
                        <label for="babies" v-cloak>{{ translator.bebes }}</label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-4">
                    <label for="hourArrival" class="pt-3 pb-1" v-cloak>
                        {{ translator.horaentrada }}
                    </label>
                    <vue-timepicker 
                        v-model="formBookingDetails.hourArrival"
                        hide-disabled-items
                        placeholder=" "
                        :hour-range="[[ booking._lodging_checkin_from_show_input, booking._lodging_checkin_to_show_input - 1 ]]"
                        :minute-interval="5"
                        :disabled="formBookingDetails.isHoursCheckinDisabled"
                    ></vue-timepicker>
                </div>
                <div class="col-4">
                    <label for="hourDeparture" class="pt-3 pb-1" v-cloak>
                        {{ translator.horasortida }}
                    </label>
                    <vue-timepicker
                        v-model="formBookingDetails.hourDeparture"
                        hide-disabled-items
                        placeholder=" "
                        :hour-range="[[ booking._lodging_checkout_from_show_input, booking._lodging_checkout_to_show_input - 1 ]]"
                        :minute-interval="5"
                        v-bind:disabled="formBookingDetails.isDisabled"
                    ></vue-timepicker>
                </div>
                <div class="col-4">
                    <label for="flight" class="pt-3 pb-1" v-cloak>
                        {{ translator.nvol }}
                    </label>
                    <input id="flight"
                           v-model="formBookingDetails.flight"
                           type="text"
                           class="form-control"
                           :disabled="formBookingDetails.isDisabled"
                           autocomplete="noComplete"/>
                </div>
            </div>

            <label for="remarks" class="pt-3 pb-1" v-cloak>
                {{ translator.observacions }}
            </label>
            <textarea rows="3"
                      id="remarks"
                      v-model="formBookingDetails.remarks"
                      type="text"
                      class="form-control"
                      :disabled="formBookingDetails.isDisabled"
                      autocomplete="noComplete"></textarea>

            <div v-if="errorFormBooking.length > 0"
                 class="alert alert-danger mt-4"
                 role="alert">
                <p class="mb-2" v-for="(error, index) in errorFormBooking" :key="index">
                    {{ error }}.
                </p>
            </div>

            <button class="btn btn-secondary mt-3"
                    v-if="!formBookingDetails.isDisabled"
                    @click.prevent.self="validateFormBooking()"
            >
                {{ translator.guardar }}
            </button>
        </div>
    </div>
</template>

<script>
    import countries from '../../assets/js/countries.js'
    import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
    import { mapState } from "vuex";

    export default {
        name: "BookingDetails",
        components: {
            VueTimepicker
        },
        computed: {
            ...mapState("utils", ["user", "translator"]),
            ...mapState("checkin", ["booking"]),
        },

        watch: {
            translator() {
                this.$store.dispatch("checkin/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId });
            },
            booking() {
                this.assignFormData();
                this.verifyCheckin();
                this.isPreLoad = false;
            },
        },

        data() {
            return {
                isPreLoad: false,
                countries: countries,
                formBookingDetails: {
                    reservation: "",
                    name: "",
                    surname: "",
                    email: "",
                    phone: "",
                    adress: "",
                    city: "",
                    country: "",
                    countryNacionality: "",
                    zip: "",
                    numDocument: "",
                    adults: "",
                    children: "",
                    babies: "",
                    hourArrival: "",
                    hourDeparture: "",
                    flight: "",
                    remarks: "",
                    isDisabled: ""
                },
                errorFormBooking: [],
            };
        },

        async created() {
            this.isPreLoad = true;
            try {
                this.translator 
                    ? await this.$store.dispatch("checkin/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.$route.params.bookingId })
                    : await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });         
            } catch (error) {
                console.error(error);
            }
        },

        methods: {
            assignFormData() {
                this.formBookingDetails.reservation = this.booking.reservation;
                this.formBookingDetails.name = this.booking.customer_first_name;
                this.formBookingDetails.surname = this.booking.customer_last_name;
                this.formBookingDetails.adress = this.booking.customer_address;
                this.formBookingDetails.city = this.booking.customer_city;
                this.formBookingDetails.country = this.booking.customer_country;
                this.formBookingDetails.countryNacionality = this.booking.customer_nationality;
                this.formBookingDetails.zip = this.booking.customer_zip;
                this.formBookingDetails.numDocument = this.booking.customer_tin;
                this.formBookingDetails.phone = this.booking.customer_phone;
                this.formBookingDetails.email = this.booking.customer_email;
                this.formBookingDetails.adults = this.booking.adults;
                this.formBookingDetails.children = this.booking.children;
                this.formBookingDetails.babies = this.booking.babies;
                this.formBookingDetails.hourArrival = this.booking.arrival_time;
                this.formBookingDetails.hourDeparture = this.booking.departure_time;
                this.formBookingDetails.flight = this.booking.customer_flight;
                this.formBookingDetails.remarks = this.booking.customer_remarks;
                this.formBookingDetails.isDisabled = this.verifyCheckin();
            },

            validateFormBooking(validar) {
                this.errorFormBooking = [];
                const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
                //let regDNI = /(([X-Z]{1})([-]?)(\d{7})([-]?)([A-Z]{1}))|((\d{8})([-]?)([a-zA-Z]{1}))/
                //let regPassaport = /[a-zA-Z]{3}[0-9]{6}/

                this.formBookingDetails.name === '' ? this.errorFormBooking.push(this.translator.faltanom) : '';
                this.formBookingDetails.surname === '' ? this.errorFormBooking.push(this.translator.faltacognom) : '';
                this.formBookingDetails.adress === '' ? this.errorFormBooking.push(this.translator.faltaadreca) : '';
                this.formBookingDetails.country === '' ? this.errorFormBooking.push(this.translator.paisorigen) : '';
                this.formBookingDetails.countryNacionality === '' ? this.errorFormBooking.push(this.translator.nacionalitat) : '';
                this.formBookingDetails.city === '' ? this.errorFormBooking.push(this.translator.faltapoble) : '';
                this.formBookingDetails.zip === '' ? this.errorFormBooking.push(this.translator.faltadip) : '';
                this.formBookingDetails.numDocument === '' ? this.errorFormBooking.push(this.translator.faltadnipassaport) : '';
                this.formBookingDetails.phone === '' ? this.errorFormBooking.push(this.translator.faltatelefon) : '';
                regEmail.test(this.formBookingDetails.email) ? '' : this.errorFormBooking.push(this.translator.faltaemail);
                //this.formBookingDetails.adults === '' || this.formBookingDetails.adults == 0 ? this.errorFormBooking.push(this.translator.faltapersones) : '';

                if (validar === 'checkin') {
                    this.errorFormBooking.length === 0 ? this.checkIn() : ''
                } else {
                    this.errorFormBooking.length === 0 ? this.bookingSave() : ''
                }
            },

            async bookingSave() {
                this.isPreLoad = true;

                try {
                    await this.$store.dispatch("checkin/postBookingSave", {
                        usr: String(this.$route.query.id).replace(/[ ]/g, '+'),
                        reservation: this.formBookingDetails.reservation,
                        language: this.booking.customer_language,
                        nom: this.formBookingDetails.name,
                        cognom: this.formBookingDetails.surname,
                        adreca: this.formBookingDetails.adress,
                        poble: this.formBookingDetails.city,
                        dip: this.formBookingDetails.zip,
                        pais: this.formBookingDetails.country,
                        telefon: this.formBookingDetails.phone,
                        email: this.formBookingDetails.email,
                        cif: this.formBookingDetails.numDocument,
                        nacionalitat: this.formBookingDetails.countryNacionality,
                        adults: this.formBookingDetails.adults,
                        joves: this.formBookingDetails.children,
                        bebes: this.formBookingDetails.babies,
                        observacions: this.formBookingDetails.remarks,
                        numvol: this.formBookingDetails.flight,
                        horaentrada: this.formBookingDetails.hourArrival,
                        horasortida: this.formBookingDetails.hourDeparture
                    });

                    // Muestro notificación
                    this.$toast.success(this.translator.guardat);
                    this.$router.push({ name: 'Booking', params: { bookingId: this.formBookingDetails.reservation }, query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } })
                } catch (error) {
                    console.error(error);
                } finally {
                    this.isPreLoad = false;
                }
            },
            verifyCheckin() {
                const formatYmd = (date) => date.toISOString().slice(0, 10);
                const compare = (a, b) => a.getTime() < b.getTime();

                parseInt(this.booking.status) >= 7 || compare(new Date(formatYmd(new Date(this.booking.departure_date))), new Date(formatYmd(new Date()))) 
                    ? this.formBookingDetails.isDisabled = "disabled" 
                    : "";
            }
        }
    };
</script>

<style scoped>
    .reserva, .reserva header {
        background: var(--white);
        font-size: 0.9rem;
    }
</style>